<template>
  <!-- eslint-disable -->

    <v-container v-if="hasPermission($route.meta.permission)"  >
         <v-row class="ma-0  ">


    
            <v-row class="ma-0 munjz-bg-grey rounded-lg">
         
            				<v-col cols="10" sm="8" class="d-flex align-center me-auto">
									<span>Team
										<v-icon>mdi-chevron-right</v-icon></span>
									<span class="text-sm-h2 text-h3">New Team Member</span>
								</v-col>
				<v-col cols="2"  class="d-flex align-center justify-end">
                <v-btn small class="blue-grey lighten-4" fab text icon to="/team"
                  ><v-icon>mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>
       

  

      <v-row class="mt-6">
                      
             
              <v-form v-model="valid" class="px-sm-16" >
                  <v-row>
        <v-col cols="12" class="d-flex flex-row align-center">
          <v-text-field
            v-model="name"
            hide-details
            
            :rules="[(val) => val.length > 0]"
            outlined
            label="Member name"
            :error-messages="errors.name"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="d-flex flex-row align-center">
          <v-text-field
            v-model="email"
            label="Email"
            hide-details
           
            outlined
            :rules="rules.emailRules"
            :error-messages="errors.email"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="d-flex flex-row align-center">
          <v-text-field
            v-model="mobile"
            label="Mobile"
            type="number"
            hide-details
            
            prefix="+"
            outlined
            :rules="rules.phoneRules"
            :error-messages="errors.mobile"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="d-flex flex-row align-center">
          <v-text-field
            v-model="password"
            label="Password"
            hide-details
            
              :rules="[(val) => val.length > 7]"
            outlined
            :error-messages="errors.password "
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectedBranches"
            :items="branches"
            item-value="id"
            item-text="name"
            hide-details
            
            deletable-chips
            outlined
             :rules="[(val) => val.length > 0]"
            chips
            small-chips
            label="Assign Branch"
            multiple
          ></v-autocomplete>
        </v-col>
                <!-- <v-col cols="12" class="d-flex flex-row align-center">
          <v-autocomplete
            v-model="selectedRole"
            :items="role_list"
            hide-details
            
            clearable
           
            item-value="id"
            item-text="name"
            outlined
            :error-messages="errors.role"
            label="Role"
          >
          </v-autocomplete>
        </v-col> -->
        <v-col cols="12" class="d-flex ">
          <v-btn  class="ms-auto me-0":disabled="!valid" color="blue darken-1" @click="saveMember" min-width="105"><v-icon left>mdi-plus</v-icon>New member </v-btn>
        </v-col>
        </v-row>
        </v-form>
      </v-row>
             </v-card>
       </v-col>
     
        </v-row>
    </v-container>
  </v-container>
  <v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
export default {
	components: {},
	data() {
		return {
			valid: false,
			User_type: "member",
			items: ["owner", "viewer", "member"],
			teamDialog: false,
			search: "",
			loading: true,
			teams: [],
			user_id: localStorage.getItem("userid"),
			search: "",
			token: localStorage.getItem("token"),
			role: localStorage.getItem("role"),
			partner_members: [],
			branches: [],

			errors: [],
			rules: {
				text_field_rules: [(v) => !!v || "Field is required"],
				numeric_field_rules: [
					(v) => !!v || "Field is required",
					(v) => !isNaN(v) || "Field must have numeric value",
					(v) => !(v < 0) || "Value should be equal or greater than 0",
				],
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) =>
						/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
						"E-mail must be valid",
				],
				phoneRules: [
					(v) => !!v || "Phone number is required",
					(v) =>
						(/^(5)[0-9]\d{7}/.test(v) && v.length == 9) ||
						"Phone number must be valid",
				],
			},
			headers: [
				{
					text: "Member name",
					align: "center",
					value: "name",
				},

				{
					text: "id",
					align: "center",
					value: "id",
				},

				{
					text: "Job Title",
					align: "center",
					value: "job_title",
				},

				{
					text: "Branch assigned",
					align: "center",
					value: "branch_assigned",
				},

				{
					text: "Actions",
					value: "actions",
					sortable: false,
				},
			],

			name: "",
			email: "",
			mobile: "",
			password: "",
			selectedBranches: [],
			selectedRole: "",
			role_list: [],
			dialogDelete: false,
			team_member_id: null,
			parent_id: localStorage.getItem("userid"),
		};
	},

	mounted() {
		this.getTeams();
		this.getBranchesList();
		// this.getRolesList();
	},
	created() {},

	methods: {
		addNewMember() {
			this.$router.push({ path: "/create-team-member" });
		},

		changememberStatus(teamMember, status) {
			axios
				.post("/api/v3/change-partner-status", {
					team_member_id: teamMember.id,
					status: status,
				})
				.then((response) => {
					if (response.data.statusCode == 200) {
						this.getTeams();
					}
				})
				.catch((error) => {
					if (error.response.data.status_code) {
						this.$router.push("/");
					}
					this.showSnackbar("Something went wrong");
				});
		},

		changememberStatusConfirm() {
			this.partner_members.splice(this.editedmemberIndex, 1);
			this.closeDelete();
		},
		getRolesList() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/b2b/roles/list", {
					headers: { "X-Authorization": this.token },
					params: {},
				})
				.then((res) => {
					this.role_list = res.data.partner_roles;
				})
				.catch((err) => console.log(err));
		},

		saveMember() {
			axios
				.post("/api/v3/store-partner-admin", {
					team_member_id: this.team_member_id,
					parent_id: this.parent_id,
					name: this.name,
					password: this.password,
					email: this.email,
					mobile: this.mobile,
					role_id: this.selectedRole,
					branches: this.selectedBranches,
				})
				.then((response) => {
					if (response.data.statusCode == 200) {
						this.getTeams();
						this.$router.push({ path: "/team" });
					} else {
						this.errors = response.data.errors;
					}
				})
				.catch((error) => {
					if (error.response.data.status_code) {
						this.$router.push("/");
					}
					this.showSnackbar("Something went wrong");
				});
		},
		getTeams() {
			axios
				.get(
					`/api/v3/get-partner-admins?user_id=${localStorage.getItem(
						"userid"
					)}`,
					{
						headers: { "X-Authorization": this.token },
					}
				)
				.then((res) => {
					this.teams = res.data.teams;
				})
				.catch((err) => {
					if (err.response.data.status_code) {
						this.$router.push("/");
					}
				})
				.finally(() => (this.loading = false));
		},
		getBranchesList() {
			axios
				.get(`/api/v3/all-partners-branches?spa=1&user_id=${this.user_id}`)
				.then((res) => {
					this.branches = res.data.data;
				});
		},
	},
};
</script>
<style>
/* .v-data-table table th {
  font-size: 18px !important;
  font-weight: bolder !important;
} */
.hover-info:hover {
	user-select: pointer;
	display: flex;
	place-content: center;
	place-items: center;
	border: 1px solid rgb(0, 141, 235);
	border-radius: 50px;
	padding: 0.5rem;
	width: 25px;
	height: 25px;
}
</style>
